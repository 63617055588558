//Colors
$white: #ECEFF4;
$gray: #3B4252;
$blue: #88C0D0;
$green: #A3BE8C;
$yellow: #EBCB8B;
$orange: #D08770;
$red: #BF616A;


@font-face {
  font-family: 'Shrikhand-Regular';
  src: url('./assets/Shrikhand-Regular.ttf')
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('./assets/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('./assets/Roboto-Medium.ttf');
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('./assets/Roboto-Bold.ttf');
}

* {
  box-sizing: border-box;
}

body {
  background: $gray;
  font-family: 'Roboto-Regular';
  text-align: center;
  height: 100vh;
  overflow: hidden;
  @media (max-width: 480px) {
    overflow-y: scroll;
  }
}

.App {
  background: $gray;
}

nav {
  font-family: 'Roboto-Bold';
  font-size: 18px;
  display: flex;
  padding-top: 5vh;
  justify-content: space-evenly;
  @media (min-width: 768px) {
    justify-content: space-around;
    float: right;
    font-size: 22px;
    padding-right: 5.5vw;
  }

  @media (min-width: 1200px) {
    font-size: calc(20px + 4 * ((100vw - 1200px) / 720));
    width: 30vw;
  }

  p {
    position: relative;
  }

  .text--yellow {
    color: $yellow;
  }

  .text--green {
    color: $green;
  }

  .text--red {
    color: $red;
  }

  .text--yellow, .text--green, .text--red {
    cursor: pointer;
    &:hover {
      filter: brightness(.75)
    }
  }
}

.text--home {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  font-family: 'Shrikhand-Regular';

  h1 {
    font-size: 45px;
    color: $white;
    margin: 0;
    text-shadow: 1.67861px 5.03583px 0px rgba(0, 0, 0, 0.35);
    @media (min-width: 768px) {
      font-size: 160px;
    }
    @media (min-width: 1200px) {
      font-size: calc(100px + 30 * ((100vw - 1200px) / 720));
    }
  }

  h2 {
    font-size: 24px;
    color: $blue;
    margin: 0;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    @media (min-width: 768px) {
      font-size: 60px;
    }
    @media (min-width: 1200px) {
      font-size: calc(45px + 15 * ((100vw - 1200px) / 720));
    }
  }

  &.minimized {
    top: 10vh;
    left: 15vw;
    @media (max-width: 767px) {
      display: none;
    }

    h1 {
      @media (min-width: 1200px) {
        font-size: 36px;
        font-size: calc(36px + 12 * ((100vw - 1200px) / 720));
      }
    }

    h2 {
      @media (min-width: 1200px) {
        font-size: 20px;
        font-size: calc(20px + 6 * ((100vw - 1200px) / 720));
      }
    }
  }
}

.social-links {
  padding: 0;
  a {
    color: white;
    font-size: 1.5em;
    padding: 0 1em;
    @media (min-width: 768px) {
      font-size: 2em;
    }
    &:hover {
      filter: brightness(90%);
    }
  }
}
