.about-section {
    display: flex;
    width: 100%;
    padding: 13vh 2rem 0;
}

.about-container {
    align-items: center;
    background: white;
    display: flex;
    justify-content: space-evenly;
    margin: 0 auto;
    padding: 2rem 2rem 2rem 4rem;
    width: 69vw;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);

    @media (max-width: 767px) {
        width: 90vw;
        padding: 2rem 1.5rem;
        flex-direction: column;
    }

    &:hover {
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
      }

    img {
        border-radius: 50%;
        max-width: 20rem;
        @media (max-width: 767px) {
            max-width: 13rem;
            order: 1;
        }
    }

    .about-text {
        padding-right: 2rem;
        @media (max-width: 767px) {
            order: 2;
            padding-right: 0;
            p {
                font-size: 1rem;
            }
        }
    }

    p {
        font-size: 2rem;
        line-height: 1.4;
        text-align: left;
        @media (min-width: 1200px) {
            font-size: calc(1.4rem + 10  * ((100vw - 1200px) / 720));
        }
    }
}